// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
/* const firebaseConfig = {
  apiKey: "AIzaSyAVrQnNdlXHWxmu1I3JGT5waoRxgIU4uNI",
  authDomain: "trade-times-demo.firebaseapp.com",
  databaseURL: "https://trade-times-demo-default-rtdb.firebaseio.com",
  projectId: "trade-times-demo",
  storageBucket: "trade-times-demo.appspot.com",
  messagingSenderId: "1061157419906",
  appId: "1:1061157419906:web:360e30b89420df03466d9b",
}; */

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3MEHQ-p5bni0eDtjNfoiQDA_xYDS_E98",
  authDomain: "trading-times1.firebaseapp.com",
  projectId: "trading-times1",
  storageBucket: "trading-times1.appspot.com",
  messagingSenderId: "848239212602",
  appId: "1:848239212602:web:ee5f9661618b51ce941d03",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Exports here!
export const db = getFirestore(app);
export const storage = getStorage(app);

/* Login details for an admin ===> kelly@admin.com && RB]q9DH7#PY5Ex& */

/* Login details for a student ===> nyquist.nwaukwa@gmail.com && "vd*K3+qu4eSQ3> */
