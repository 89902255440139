import { useEffect, lazy, Suspense } from "react";

import "./App.css";
import { Routes, Route } from "react-router-dom";

import Header from "./components/Header";
import MainBody from "./components/MainBody";

import { useAuthContext } from "./store/auth-context";

/* ********************** */
import useDetail from "./store/details";
/* ********************** */

import AOS from "aos";
import "aos/dist/aos.css";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Course = lazy(() => import("./pages/Course"));
const FreeCourses = lazy(() => import("./pages/FreeCourses"));
const Brokers = lazy(() => import("./pages/Brokers"));
const MentorshipPlan = lazy(() => import("./pages/MentorshipPlan"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const Faq = lazy(() => import("./pages/Faq"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const AccountManagement = lazy(() => import("./pages/AccountManagement"));
const Signals = lazy(() => import("./pages/Signals"));
const PropFilms = lazy(() => import("./pages/PropsFilm"));
const SignUp = lazy(() => import("./pages/SignUp"));
const AdvancedCourses = lazy(() => import("./pages/AdvancedCourses"));
const AdvancedCoursesList = lazy(() => import("./pages/AdvancedCoursesList"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Transacation = lazy(() => import("./pages/Transacations"));
const GetStarted = lazy(() => import("./pages/GetStarted"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Profile = lazy(() => import("./pages/Profile"));
const AccountBalance = lazy(() => import("./pages/AccountBalance"));
const Withdrawal = lazy(() => import("./pages/Withdrawal"));
const CourseMaker = lazy(() => import("./pages/CourseMaker"));
const GenericCourses = lazy(() => import("./pages/GenericCourses"));
const Master = lazy(() => import("./pages/Master"));
const EachUser = lazy(() => import("./pages/EachUser"));

const App = () => {
  const { isLogin } = useAuthContext();

  const { details } = useDetail();

  useEffect(() => {
    AOS.init({
      duration: "1500",
    });
  }, []);

  const spinner = (
    <div className="text-center">
      <div
        className="spinner-border"
        style={{ width: "13rem", height: "13rem", marginTop: 300 }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
  return (
    <Suspense fallback={spinner}>
      <div className="body-color">
        <Header />
        <MainBody>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/course" element={<Course />} />
            <Route path="/free-course" element={<FreeCourses />} />
            <Route path="/brokers" element={<Brokers />} />
            <Route path="/mentorship" element={<MentorshipPlan />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/faq" element={<Faq />} />
            {!isLogin && <Route path="/create" element={<CreateAccount />} />}
            <Route path="/account-management" element={<AccountManagement />} />
            <Route path="/signal" element={<Signals />} />
            <Route path="/prop-films" element={<PropFilms />} />
            {!isLogin && <Route path="/login" element={<SignUp />} />}
            <Route path="/advanced-course" element={<AdvancedCourses />} />
            {isLogin && (
              <Route path="/transacations" element={<Transacation />} />
            )}
            {isLogin && <Route path="/get-started" element={<GetStarted />} />}
            {isLogin && <Route path="/balance" element={<AccountBalance />} />}
            {isLogin && <Route path="/withdrawal" element={<Withdrawal />} />}
            {!isLogin && (
              <Route path="/forgot-password" element={<ForgotPassword />} />
            )}
            {isLogin && details.plan && (
              <Route
                path="/all-advanced-course"
                element={<AdvancedCoursesList />}
              />
            )}
            {isLogin && <Route path="/profile" element={<Profile />} />}
            {isLogin && details.master && (
              <Route path="/post-course" element={<CourseMaker />} />
            )}
            {isLogin && (
              <Route path="/single-course" element={<GenericCourses />} />
            )}
            {isLogin && details.master && (
              <Route path="/master" element={<Master />} />
            )}
            {isLogin && details.master && (
              <Route path="/each-user" element={<EachUser />} />
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </MainBody>
      </div>
    </Suspense>
  );
};

export default App;
