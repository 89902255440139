import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext({
  idToken: "",
  isLogin: null,
  userDetails: "",
  login: (idToken) => {},
  logout: () => {},
  shareUserObject: () => {},
});

const AuthContextProvider = (props) => {
  const navigate = useNavigate();
  const intialToken = localStorage.getItem("token");
  const emailUsername = localStorage.getItem("email");
  const [token, setToken] = React.useState(intialToken);
  const [emailName, setEmailName] = React.useState(emailUsername);
  const isLogin = !!token;

  const loginHandler = (idToken, emailName) => {
    setToken(idToken);
    setEmailName(emailName);
    localStorage.setItem("email", emailName);
    localStorage.setItem("token", idToken);
  };

  const logoutHandler = () => {
    setToken(null);
    setEmailName(null);
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    navigate("/");
  };

  const shareUserHandler = (userObject) => {
    // console.log(userObject);
  };

  // console.log(localStorage.getItem("MANUNITED"));

  return (
    <AuthContext.Provider
      value={{
        idToken: token,
        emailName,
        isLogin,
        login: loginHandler,
        logout: logoutHandler,
        shareUserObject: shareUserHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
