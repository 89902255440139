import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase-config";

const useDetail = () => {
  const [details, setDetails] = useState({});
  useEffect(() => {
    // Function to fetch value from Firestore
    const fetchValue = async () => {
      try {
        const docRef = doc(db, "users", localStorage.getItem("email"));
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setDetails({ ...docSnap.data() });
        } else {
          // docSnap.data() will be undefined in this case
          console.error("No such document!");
        }
      } catch (error) {
        // console.error("Error fetching value:", error);
      }
    };

    // Fetch value on component mount
    fetchValue();
  }, []);

  // console.log(details);

  return {
    details,
  };
};

export default useDetail;
