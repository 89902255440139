import { Link, NavLink, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import logo from "../assets/TradingTime.png";

import { useAuthContext } from "../store/auth-context";

import classes from "./Header.module.css";

/* ******************************* */
import { FaUserCircle } from "react-icons/fa";
import { IoIosLogOut } from "react-icons/io";
import { IoMdLogIn } from "react-icons/io";
import { HiMiniUserPlus } from "react-icons/hi2";
import { MdVerifiedUser } from "react-icons/md";
import { AiOutlineTransaction } from "react-icons/ai";
import { GiPadlock } from "react-icons/gi";
import { GiPadlockOpen } from "react-icons/gi";
import { MdOutlineAccountBalance } from "react-icons/md";
import { MdNoAccounts } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { BiMoneyWithdraw } from "react-icons/bi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
import { PiListDashesFill } from "react-icons/pi";
import { FaKey } from "react-icons/fa6";

import { IoHomeOutline } from "react-icons/io5";
import { LuInfo } from "react-icons/lu";
import { FaBookOpen } from "react-icons/fa";
import { BiCandles } from "react-icons/bi";
import { FaPersonChalkboard } from "react-icons/fa6";
import { MdContactMail } from "react-icons/md";
import { FaQuestion } from "react-icons/fa";

import NavDropdown from "react-bootstrap/NavDropdown";

import { useState } from "react";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase-config";
import { Button, Modal } from "react-bootstrap";
import useDetail from "../store/details";
/* ******************************* */

const Header = () => {
  /* ******************************* */
  const { isLogin, logout } = useAuthContext();
  /* ******************************* */
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const { details } = useDetail();

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleDelete = () => {
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:delete?key=AIzaSyC3MEHQ-p5bni0eDtjNfoiQDA_xYDS_E98",
      {
        method: "POST",
        body: JSON.stringify({ idToken: localStorage.getItem("token") }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          console.log(data);
          deleteDoc(doc(db, "users", localStorage.getItem("email")));
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          navigate("/");
        });
      } else {
        return response.json().then((err) => console.log(err.error.message));
      }
    });

    setShow(false);
  };

  return (
    <nav style={{ paddingBottom: 85 }}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>
            <i>
              You sure you want to delete this account as it can't be recovered
              once deleted!
            </i>
          </b>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete Now
          </Button>
        </Modal.Footer>
      </Modal>
      {["lg"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="mb-3 py-1"
          style={{
            zIndex: 999,
            background: "#4D4E70",
            color: "white",
            height: 85,
          }}
          fixed="top"
          variant="dark"
        >
          <Container>
            <Navbar.Brand as={Link} to="/">
              <span>
                <img
                  src={logo}
                  alt="Trading Times Logo"
                  style={{
                    marginTop: -20,
                    width: 125,
                    height: 125,
                    borderRadius: "55%",
                  }}
                />
              </span>
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              style={{
                border: "none",
                marginTop: -30,
              }}
            >
              <PiListDashesFill size={30} />
            </Navbar.Toggle>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              style={{ background: "#4D4E70", width: "80%" }}
            >
              <Offcanvas.Header closeButton>
                <img
                  src={logo}
                  alt="Trading Times Logo"
                  style={{
                    display: "inline",
                    width: 150,
                    height: 150,
                    borderRadius: "55%",
                  }}
                />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <NavLink
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    as={Link}
                    to="/"
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <IoHomeOutline style={{ marginRight: 5 }} />
                    Home
                  </NavLink>
                  <NavLink
                    as={Link}
                    to="/about"
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <LuInfo style={{ marginRight: 5 }} />
                    About
                  </NavLink>
                  <NavLink
                    as={Link}
                    to="/course"
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <FaBookOpen style={{ marginRight: 5 }} />
                    Courses
                  </NavLink>
                  <NavLink
                    as={Link}
                    to="/brokers"
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <BiCandles style={{ marginRight: 5 }} />
                    Brokers
                  </NavLink>

                  <NavLink
                    as={Link}
                    to="/mentorship"
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <FaPersonChalkboard style={{ marginRight: 5 }} />
                    Mentorship
                  </NavLink>
                  <NavLink
                    as={Link}
                    to="/contact-us"
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <MdContactMail style={{ marginRight: 5 }} />
                    Contact us
                  </NavLink>
                  <NavLink
                    as={Link}
                    to="/faq"
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                      textDecoration: "none",
                    }}
                    className={({ isActive }) =>
                      isActive ? classes.active : classes["not-active"]
                    }
                  >
                    <FaQuestion style={{ marginRight: 3 }} />
                    FAQ
                  </NavLink>
                </Nav>
                <Nav className="me-right">
                  <NavDropdown
                    align="end"
                    title={
                      isLogin ? (
                        <FaUserCircle size="2.5rem" />
                      ) : (
                        <MdNoAccounts size="2.5rem" />
                      )
                    }
                    id="basic-nav-dropdown"
                  >
                    {!isLogin || details.name ? (
                      <>
                        {isLogin && (
                          <NavDropdown.Item>
                            <h4>
                              <span>
                                {details.name ? (
                                  <div
                                    style={{
                                      width: 50,
                                      height: 50,
                                      background: "black",
                                      color: "white",
                                      borderRadius: 50,
                                      textAlign: "center",
                                      paddingTop: 10,
                                    }}
                                  >
                                    {details.name ? (
                                      <span>{details.name[0]}</span>
                                    ) : (
                                      <span>A</span>
                                    )}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      width: 50,
                                      height: 50,
                                      background: "black",
                                      color: "white",
                                      borderRadius: 50,
                                      textAlign: "center",
                                      paddingTop: 10,
                                    }}
                                  >
                                    C
                                  </div>
                                )}
                              </span>{" "}
                              {details.name ? (
                                <span>Welcome, {details.firstName}</span>
                              ) : (
                                <span>Connect to the Internet</span>
                              )}
                            </h4>
                            <h6>
                              {details.name ? (
                                <span style={{ fontSize: 14 }}>
                                  <MdVerifiedUser /> {details.email}
                                </span>
                              ) : (
                                <span>Connect to the Internet</span>
                              )}
                            </h6>
                          </NavDropdown.Item>
                        )}

                        {isLogin && (
                          <NavDropdown.Item>
                            <h6>Course Section</h6>
                          </NavDropdown.Item>
                        )}
                        {isLogin && (
                          <NavDropdown.Item
                            as={Link}
                            className={`mx-2`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                            to="/free-course"
                          >
                            <GiPadlockOpen /> Free Courses
                          </NavDropdown.Item>
                        )}
                        {isLogin && (
                          <NavDropdown.Item
                            as={Link}
                            className={`mx-2`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                            to={
                              details.plan
                                ? "/all-advanced-course"
                                : "/advanced-course"
                            }
                          >
                            {details.plan ? (
                              <span>
                                <GiPadlockOpen />
                              </span>
                            ) : (
                              <span>
                                <GiPadlock />
                              </span>
                            )}{" "}
                            <span
                              className={details.plan ? "active" : "text-muted"}
                            >
                              Advanced Courses
                            </span>
                          </NavDropdown.Item>
                        )}

                        {isLogin && (
                          <NavDropdown.Item
                            as={Link}
                            className={`mx-2`}
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                            to="/signal"
                          >
                            {details.signal ? (
                              <span>
                                <GiPadlockOpen />
                              </span>
                            ) : (
                              <span>
                                <GiPadlock />
                              </span>
                            )}{" "}
                            <span
                              className={
                                details.signal ? "active" : "text-muted"
                              }
                            >
                              Signals
                            </span>
                          </NavDropdown.Item>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && (
                          <NavDropdown.Item as={Link} to="/account-management">
                            <MdOutlineAccountBalance /> Account Management
                          </NavDropdown.Item>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && (
                          <NavDropdown.Item as={Link} to="/transacations">
                            <AiOutlineTransaction /> Transction History
                          </NavDropdown.Item>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && (
                          <NavDropdown.Item as={Link} to="/get-started">
                            <RiMoneyDollarCircleLine /> Deposit
                          </NavDropdown.Item>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && (
                          <NavDropdown.Item as={Link} to="/withdrawal">
                            <BiMoneyWithdraw /> Withdraw
                          </NavDropdown.Item>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && (
                          <NavDropdown.Item as={Link} to="/profile">
                            <CgProfile /> Profile
                          </NavDropdown.Item>
                        )}

                        {isLogin && <NavDropdown.Divider />}
                        {isLogin && (
                          <NavDropdown.Item as={Link} to="/balance">
                            <MdOutlineAccountBalance /> Balance
                          </NavDropdown.Item>
                        )}
                        {!isLogin && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <NavDropdown.Item
                              as={Link}
                              className={`mx-2 my-3 btn`}
                              style={{
                                width: 130,
                                background: "black",
                                color: "white",
                                textAlign: "center",
                                borderRadius: 50,
                                padding: "10px 0",
                              }}
                              to="/login"
                            >
                              Login <IoMdLogIn />
                            </NavDropdown.Item>
                          </div>
                        )}
                        {!isLogin && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <NavDropdown.Item
                              as={Link}
                              className={`mx-2 my-3 btn `}
                              style={{
                                width: 130,
                                background: "green",
                                color: "white",
                                textAlign: "center",
                                borderRadius: 50,
                                padding: "10px 0",
                              }}
                              to="/create"
                            >
                              Sign Up <HiMiniUserPlus />
                            </NavDropdown.Item>
                          </div>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && details.master && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <NavDropdown.Item
                              as={Link}
                              className={`mx-2 my-1 btn`}
                              style={{
                                width: 180,
                                background: "red",
                                color: "white",
                                textAlign: "center",
                              }}
                              to="/master"
                            >
                              <FaKey /> Master
                            </NavDropdown.Item>
                          </div>
                        )}

                        {isLogin && <NavDropdown.Divider />}

                        {isLogin && details.master && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <NavDropdown.Item
                              as={Link}
                              className={`mx-2 my-1 btn`}
                              style={{
                                width: 180,
                                background: "green",
                                color: "white",
                                textAlign: "center",
                              }}
                              to="/post-course"
                            >
                              <IoAddCircle /> Post Course
                            </NavDropdown.Item>
                          </div>
                        )}

                        {isLogin && <NavDropdown.Divider />}
                        {isLogin && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <NavDropdown.Item
                              as={Link}
                              className={`mx-2 my-3 btn`}
                              style={{
                                width: 180,
                                background: "pink",
                                color: "red",
                                textAlign: "center",
                              }}
                              onClick={handleShow}
                            >
                              <MdDeleteOutline /> Delete Account
                            </NavDropdown.Item>
                          </div>
                        )}

                        {isLogin && <NavDropdown.Divider />}
                        {isLogin && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <NavDropdown.Item
                              as={Link}
                              className={`mx-2 my-3 btn`}
                              style={{
                                width: 130,
                                background: "pink",
                                color: "red",
                                textAlign: "center",
                              }}
                              to="/"
                              onClick={() => logout()}
                            >
                              Logout <IoIosLogOut />
                            </NavDropdown.Item>
                          </div>
                        )}
                      </>
                    ) : (
                      <NavDropdown.Item>
                        Try to refresh, No connection to the internet...
                      </NavDropdown.Item>
                    )}
                  </NavDropdown>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </nav>
  );
};

export default Header;
